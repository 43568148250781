@font-face {
  font-family: "Sofia Pro-Medium";
  src: url("../../Fonts/SofiaPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../../Fonts/SofiaPro-Light.otf") format("opentype");
}

:root {
  --theme-color: #fd7248;
}

.hoverable_element {
  transition: all 0.4s;
}

.hoverable_element:hover {
  filter: invert(1);
}

.allocation-dashboard {
  font-family: "Sofia Pro", sans-serif;
}

.allocation-dashboard .medium-font {
  font-family: "Sofia Pro-Medium", sans-serif;
}

.allocation-dashboard .danger-text {
  color: #cc0000;
}

.allocation-dashboard .ant-tabs-extra-content {
  width: 16%;
}

.allocation-dashboard .ant-spin-dot i {
  background-color: var(--theme-color);
}

.allocation-dashboard .ant-input-affix-wrapper {
  padding: 0px;
  border-radius: 8px;
}

.allocation-dashboard .ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: var(--theme-color);
  box-shadow: none;
}

.allocation-dashboard .ant-input-prefix {
  margin: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.allocation-dashboard .ant-input-prefix:hover {
  transform: scale(1.2);
  color: var(--theme-color);
}

.allocation-dashboard .ant-input {
  background-color: unset;
  padding-left: 0 !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.allocation-dashboard .ant-input-group-addon {
  width: fit-content;
}

.allocation-dashboard .ant-btn-icon-only.ant-btn-sm {
  box-shadow: none;
  cursor: pointer;
  transition: all 0.3s;
}

.allocation-dashboard .ant-btn-icon-only.ant-btn-sm:hover {
  transform: scale(1.2);
  color: var(--theme-color);
}

.allocation-dashboard .ant-btn-icon-only.ant-btn-sm:hover,
.ant-btn-icon-only.ant-btn-sm:focus,
.ant-btn-icon-only.ant-btn-sm:active {
  background-color: unset;
}

.allocation-dashboard .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--theme-color);
}

.allocation-dashboard .ant-tabs-ink-bar {
  height: 1px !important;
  background-color: var(--theme-color);
}

.allocation-dashboard a {
  color: inherit; /* Inherit the color from the parent */
  text-decoration: none; /* Remove underline */
}

.allocation-dashboard a svg {
  height: 16px;
  width: 16px;
}

.allocation-dashboard .ant-pagination-item-active {
  border-color: #f1f5fb;
  background-color: #f1f5fb;
  color: inherit;
}

.allocation-dashboard .ant-pagination-item a:hover,
.allocation-dashboard .ant-pagination-item-active a:hover {
  color: var(--theme-color);
}

.allocation-dashboard .ant-pagination-item-link {
  background-color: #f1f5fb;
}

.allocation-dashboard .ant-pagination-item-active a {
  color: inherit;
}

.allocation-dashboard .ant-pagination-next:focus .ant-pagination-item-link,
.allocation-dashboard .ant-pagination-next:hover .ant-pagination-item-link,
.allocation-dashboard .ant-pagination-prev:focus .ant-pagination-item-link,
.allocation-dashboard .ant-pagination-prev:hover .ant-pagination-item-link {
  color: var(--theme-color);
  border-color: var(--theme-color);
}

.allocation-dashboard
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  border-color: var(--theme-color);
}

.allocation-dashboard .ant-select-item-option-active {
  background-color: #ffb39c79;
}

.allocation-dashboard .ant-select-item-option-active,
.allocation-dashboard .ant-select-item-option-selected {
  color: var(--theme-color);
}

.allocation-dashboard .ant-table-wrapper {
  height: 60vh;
  overflow-x: auto;
}
