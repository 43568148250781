.access-list {
  display: flex;
  flex-direction: column;
}

.ant-table {
  font-size: 0.75rem;
}

.ant-table-thead > tr > th {
  padding: 0.15rem 0.75rem;
  background-color: #f5f6fa;
}

.ant-table-column-sorters {
  padding: 8px 12px;
}

.ant-table-tbody > tr > td {
  padding: 0.15rem 0.75rem;
}

.user-table-button {
  border-radius: 2px;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.15rem;
  font-size: 0.9rem;
  margin: 0.15rem 0;
}

.user-page-header {
  padding: 0.5rem 0;
  border: 1px solid rgb(235, 237, 240);
  text-align: center;
  margin-bottom: 0.5rem;
}

.user-page-header h2 {
  margin-top: 0.15rem;
  margin-bottom: 0;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 2rem;
  border-color: rgb(235, 237, 240);
  border-radius: 2px;
  width: 120px;
}

// Added by developer

.ant-select-selection-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Added by developer

.ant-select-selection-search-input {
  font-size: 0.75rem;
}

.ant-input-sm {
  margin-left: 0.25rem;
  height: 1.9rem;
  //border-radius: 2px;
}

.ant-collapse {
  border-radius: 2px;
  background-color: inherit;
}

.ant-btn-sm {
  height: 2rem;
  border-radius: 2px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0.5rem 2rem;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0.75rem;
}

.ant-input-affix-wrapper {
  padding: 0 0.6rem 0 0.2rem;
  //border-radius: 2px;
}

.ant-input {
  font-size: 0.8rem;
}

.ant-input:placeholder-shown {
  font-size: 0.8rem;
}

.ant-input-affix-wrapper > input.ant-input {
  // padding: 0.3rem;
  padding: 0.6rem 1rem;
}

.user-search-button {
  border: 1px solid rgb(235, 237, 240);
  border-radius: 2px;
}

.user-search-button:hover {
  background-color: #f1f2f6;
}

.user-search-button > span {
  padding-top: 1px;
  font-size: 0.95rem;
}

.user-search-button > img {
  padding-bottom: 4px;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  border-radius: 2px;
  min-height: inherit;
  font-size: 0.8rem;

  // Added by Developer
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  // Added by Developer
}

.ant-select-multiple .ant-select-selection-item {
  height: inherit;
  border-radius: 2px;
}

.disable-button {
  color: #d9cece;
}

// Added by Developer
.otpLoginCheck .ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.action-div-userlist {
  display: inline-flex;
}
// Added by Developer

.user-table,
.ant-btn-primary:hover {
  color: #ed1a69;
  background-color: white;
}

.user-table,
.ant-btn-primary:active,
.user-table,
.ant-btn-primary:focus {
  color: white !important;
  background-color: #ed1a69 !important;
}
