// Number Invenory - Outbound Page CSS Start

.dropdown .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: #000000e5 !important;
    font-weight: 400 !important;
  }
  
  .dropdown .ant-select-selector:focus,
  .dropdown .ant-select-selector:active {
    box-shadow: none !important;
  }
  
  .info_btn,
  .info_btn:active,
  .info_btn:focus {
    background-color: var(--blue);
    color: var(--white);
    border-radius: 8px;
  }
  
  .info_btn:hover {
    background-color: transparent;
    color: var(--blue);
    border-color: var(--blue);
  }
  
  .spam_filter_card_div {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 8px 4px;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .spam_filter_card_div::-webkit-scrollbar {
    display: none;
  }
  
  .spam_filter_card {
    flex: 0 0 auto;
    cursor: pointer;
    max-width: 21vw;
    margin-right: 16px;
  }
  
  .spam_cards {
    border-radius: 8px !important;
    border-color: #0000003f !important;
  }
  
  .small_spam_cards {
    border-radius: 8px !important;
    border-color: #0000003f !important;
    cursor: pointer;
    transition: all 0.3s;
    min-height: 100%;
    min-width: 21vw;
  }
  
  .small_spam_cards:hover {
    transform: scale(1.02);
    box-shadow: 0px 5px 8px -4px #07090e52;
    z-index: 44;
  }
  
  .small_spam_cards p {
    line-height: 17px;
    font-size: 0.74rem;
  }
  
  .active_small_spam_cards {
    border-color: var(--blue) !important;
    color: var(--white) !important;
    background-color: var(--blue) !important;
  }
  
  .active_small_spam_cards p,
  .active_small_spam_cards h5 {
    color: var(--white) !important;
  }
  
  .counts {
    font-size: 2.3rem;
    font-weight: bolder;
  }
  
  .table_filter_btn {
    border-color: var(--blue) !important;
    color: var(--blue) !important;
    border-radius: 8px !important;
  }
  
  .table_filter_btn:hover,
  .table_filter_btn:focus,
  .table_filter_btn:active {
    background-color: var(--blue) !important;
    color: var(--white) !important;
  }
  
  #search_num::placeholder {
    color: #00000060 !important;
    font-weight: 500 !important;
  }
  
  .ant-modal-body button {
    float: none !important;
  }
  
  // Number Invenory - Outbound Page CSS End

  // Multiple Accounts Modal CSS Starts

.customized_modal .ant-modal-close-x {
    background-color: #d1d8e2 !important;
    color: var(--white) !important;
    line-height: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 44px;
    margin: -7px -7px;
    transition: all 0.3s;
  }
  
  .customized_modal .ant-modal-close-x:hover {
    transform: scale(1.1);
    color: black !important;
  }
  
  .customized_modal .ant-modal-content {
    border-radius: 16px !important;
  }
  
  .customized_modal .ant-modal-body {
    border-radius: 16px !important;
    padding: 0 !important;
    background-color: #f2f5f9;
  }
  
  .customized_modal .ant-modal-footer {
    background-color: #f2f5f9 !important;
  }
  
  .customized_modal .ant-spin {
    color: var(--blue) !important;
  }
  
  .customized_modal .ant-spin-dot i {
    background-color: var(--blue) !important;
  }
  
  .customized_modal_body {
    // height: 70vh;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }
  
  .customized_modal_body .phone_number {
    height: 22vh;
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  .customized_modal_body .other_details {
    height: 70%;
    border-radius: 16px !important;
    background-color: #d1d8e2;
  }
  
  .other_details .name_details {
    height: 16%;
  }
  
  .other_details .cust_and_product {
    height: 84%;
  }
  
  .cust_and_product .customer_name {
    border-radius: 22px;
    background-color: #e3e8ef;
  }
  
  .cust_and_product .product .icon_bg {
    background-color: #e3e8ef;
    cursor: pointer;
  }
  
  .customized_modal_body .name_icon {
    // display: flex;
    margin: -36px 36px;
    // justify-content: center;
    // align-items: center;
    // font-size: 2rem;
    // font-weight: 500;
    // color: var(--white);
    // letter-spacing: 2px;
    // background-color: #667489;
    // height: 64px;
    // width: 64px;
  }
  
  .customized_modal_body .name_icon .avatar {
    width: 58px;
    height: 58px;
  }
  
  .customized_modal_body .bold_txt {
    font-weight: 600;
  }
  
  .multiple_loanAcc_cards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .multiple_loanAcc_cards::-webkit-scrollbar {
    display: none;
  }
  
  .multiple_loanAcc_cards .loan_card {
    border-radius: 13px;
    width: 40%;
    flex: 0 0 auto;
    // height: 20vh;
  }
  
  .multiple_loanAcc_cards .product_icon {
    background-color: #667489;
    height: 22px;
    width: 22px;
    color: var(--white);
  }
  
  .multiple_loanAcc_cards .ant-card {
    background: #e3e8ef;
  }
  
  .multiple_loanAcc_cards .ant-card-body {
    padding: 8px !important;
  }
  
  .product_icon .anticon {
    font-size: 18px;
  }
  
  .loan_card .small_txt {
    font-size: 12px;
  }
  
  .loan_card .loan_account_number {
    color: #404f66;
    border: 1px solid #8080805e;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .color_transition {
    transition: all 0.3s;
  }
  
  .color_transition:hover {
    background-color: #404f66;
    color: var(--white);
  }
  
  .transition_cls {
    transition: all 0.3s;
    cursor: pointer;
  }
  
  .transition_cls:hover {
    // transform: scale(1.1);
    filter: invert(1);
  }
  
  // Multiple Accounts Modal CSS End
  
  // Number Invenory - Inbound Page CSS Start
  
  .inventory_pagination .ant-pagination-item-active {
    border-color: var(--blue) !important;
    background-color: var(--blue) !important;
  }
  
  .inventory_pagination .ant-pagination-item a:hover {
    color: var(--blue) !important;
  }
  
  .inventory_pagination .ant-pagination-item-active a:hover {
    color: var(--white) !important;
  }
  
  .inventory_pagination
    .ant-select-single:not(.ant-select-disabled):hover
    .ant-select-selector,
  .inventory_pagination
    .ant-select:not(.ant-select-disabled):hover
    .ant-select-selector {
    border-color: var(--blue) !important;
  }
  
  .customized_fields .ant-select-selector {
    border-radius: 8px !important;
    width: 15vw !important;
  }
  
  .customized_fields .ant-input.ant-input,
  .customized_fields .ant-input-affix-wrapper {
    border-radius: 8px !important;
  }
  
  .customized_fields .ant-input-affix-wrapper:focus,
  .customized_fields .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    border-color: var(--blue);
    box-shadow: 0 0 0 2px #1a8eed33;
  }
  
  .multi_customers_select .ant-select-selector {
    width: 22vw !important;
  }
  
  .customized_fields .ant-input.ant-input:hover,
  .customized_fields .ant-input.ant-input:focus,
  .customized_fields .ant-input.ant-input:active,
  .customized_fields .ant-select-selector:hover,
  .customized_fields .ant-select-selector:focus,
  .customized_fields .ant-select-selector:active {
    border-color: var(--blue) !important;
    box-shadow: none !important;
  }
  
  .tab_pane .ant-tabs-top .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  
  .tab_pane .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--blue) !important;
  }
  
  .tab_pane .ant-tabs-ink-bar {
    background-color: var(--blue) !important;
  }
  
  .single_modal_form .ant-input.ant-input,
  .single_modal_form
    .ant-select-single:not(.ant-select-disabled)
    .ant-select-selector {
    background-color: transparent !important;
    border-color: #00000024 !important;
    border-radius: 10px !important;
  }
  
  .single_modal_form .ant-input.ant-input:hover,
  .single_modal_form .ant-input.ant-input:focus,
  .single_modal_form .ant-input.ant-input:active {
    border-color: var(--blue) !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .single_modal_form
    .ant-select-single:not(.ant-select-disabled):hover
    .ant-select-selector,
  .single_modal_form
    .ant-select-single:not(.ant-select-disabled):focus
    .ant-select-selector,
  .single_modal_form
    .ant-select-single:not(.ant-select-disabled):active
    .ant-select-selector {
    background-color: transparent !important;
    border-color: var(--blue) !important;
    box-shadow: none !important;
  }
  
  .single_modal_form .ant-form-item {
    margin-bottom: 0px !important;
  }
  
  .single_add_btn {
    background-color: transparent;
    border-radius: 10px;
    border-color: #00000024;
  }
  
  .single_add_btn:hover,
  .single_add_btn:focus,
  .single_add_btn:active {
    background-color: transparent !important;
    border-color: var(--blue) !important;
    color: var(--blue) !important;
    box-shadow: none !important;
  }
  
  .select_options {
    color: black !important;
  }
  
  .select_options:hover {
    background-color: #f2f5f9 !important;
  }
  
  .file_upload .ant-upload.ant-upload-drag {
    background-color: transparent !important;
    border-radius: 13px !important;
    border-width: 2px !important;
    border-color: #0000002c !important;
  }
  
  .file_upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #00000059;
  }
  
  .file_upload .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #0000008a !important;
  }
  
  .customized_btn {
    background-color: transparent !important;
    border: 1px solid #00000054;
    border-radius: 5px !important;
  }
  
  .customized_btn:hover,
  .customized_btn:focus,
  .customized_btn:active {
    background-color: transparent !important;
    border-color: var(--blue) !important;
    color: var(--blue) !important;
    box-shadow: none !important;
  }
  
  .customized_table .ant-table-tbody {
    font-weight: 100 !important;
    color: black !important;
    font-size: 13px;
  }
  
  .customized_table .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
  }
  
  .customized_table .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: var(--blue) !important;
  }
  
  .customized_table .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-input:hover + .ant-checkbox-inner {
    border-color: var(--blue);
  }
  
  .customized_table .ant-checkbox-checked:after {
    border: 1px solid var(--blue) !important;
  }
  
  .customized_table .ant-table-tbody > tr.ant-table-row-selected:hover > td,
  .customized_table .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #dcf4ff !important;
  }
  
  .customized_sort_icon {
    cursor: pointer;
    display: block;
    font-size: 0.8rem !important;
    transition: all 0.3s;
  }
  
  .customized_sort_icon:hover,
  .customized_sort_icon:active,
  .customized_sort_icon:focus {
    transform: scale(1.1);
    color: var(--blue) !important;
  }
  
  .ant-checkbox_custom .ant-checkbox-wrapper {
    font-weight: 400 !important;
    transition: all 0.3s;
  }
  
  .ant-checkbox_custom .ant-checkbox-wrapper:hover {
    text-decoration: underline !important;
    color: #ed1a69 !important;
  }
  
  .ant-checkbox_custom .ant-checkbox-inner {
    border: 1px solid #939393 !important;
  }
  
  .ant-checkbox_custom .ant-checkbox {
    top: 4px !important;
  }
  
  // .customized_table .ant-table {
  //   border: 1px solid var(--blue)5e !important;
  // }
  // Number Invenory - Inbound Page CSS End