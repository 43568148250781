@import '../variables/theme';

.change_password .ant-modal-content{
    border-radius: 12px;
}

.change_password .ant-modal-header{
    border-radius: 12px 12px 0px 0px;
}

.change_password .ant-input-affix-wrapper{
    border-radius: 5px;
    border-color: #d9d9d9;
}

.change_password .ant-layout-footer{
    background: #fff;
    padding: 12px 0px;
}

.password_titles{
    padding: 8px;
}