@import '_contactDetails.scss';
@import 'todoList.scss';
@import 'numberInventory.scss';
@import '_allocationDashboard.scss';
@import '_userModule.scss';

:root {
  --white: #fff;
  --success-bg: #dcfce7;
  --error-bg: #ffeded;
  --succes-dark: #17a34a;
  --error-dark: #a31717;
  --success-border: #87efac;
  --error-border: #ef8787;
  --blue: #3e79f7;
  --grey-border: #f0f0f0;
  --grey-bg: #f2f5f7;
  --grey-border-dark: #dfdfdf;
}

.prev-btn,
.next-btn {
  font-size: 2rem;
  margin-right: 0.7rem;
  cursor: pointer;
}

.site-page-header {
  border-radius: 0;
}

.ant-picker {
  padding: 9px 11px 9px !important;
}

.spocto360_icons {
  width: 22px;
  height: 22px;
  cursor: pointer;
}