
// USER MODULE CSS
.ant-message {
    z-index: 1060 !important;
  }
  //Success Message Design
  .ant-message-success .anticon,
  .custom-alert-msg > .anticon:has(.success) {
    border: 2px solid var(--success-border);
    border-radius: 13px;
    padding: 3px;
  }
  .custom-alert-msg > .anticon:has(.error) {
    border: 2px solid var(--error-border);
    border-radius: 13px;
    padding: 3px;
  }
  .ant-message-success > span,
  .ant-message-success .anticon {
    color: var(--succes-dark);
  }
  .ant-message-notice-content:has(.ant-message-success),
  .custom-alert-msg:has(.success) {
    background-color: var(--success-bg) !important;
    color: var(--succes-dark) !important;
    border-radius: 6px;
  }
  .ant-message-notice-content:has(.ant-message-error),
  .custom-alert-msg:has(.error) {
    background-color: var(--error-bg) !important;
    color: var(--error-dark) !important;
    border-radius: 6px;
  }
  .ant-message-error .anticon,
  .custom-alert-msg > .anticon:has(.error) {
    border: 2px solid var(--error-border);
    border-radius: 13px;
    padding: 3px;
  }
  .ant-message-error .anticon, .custom-alert-msg >.anticon:has(.error){
    border: 2px solid var(--error-border);
    border-radius: 13px;
    padding: 3px;
  }
  
  .custom-alert-msg {
    padding: 0.5rem;
    font-size: 12px;
    text-align: center;
    margin: 0 40px;
  }
  
  .bg-success-dark {
    background: var(--succes-dark) !important;
    color: var(--success-border);
  }
  .bg-error-dark {
    background: var(--error-dark) !important;
    color: var(--error-border);
  }
  .bg-error-dark,
  .bg-success-dark {
    color: var(--white);
    border-radius: 8px;
    padding: 2px;
    width: 16px;
  }
  .ant-table-tbody > tr > td {
    padding: 0.4rem 0.8rem;
  }
  .no-px>.ant-card-body{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .custom-grey-border{
    border: 1px solid #D0D5DD;
  }
  .font-notice{
    color: #72849a;
    font-size: 12px;
  }