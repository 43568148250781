//Contact Detail Page
.muted_img {
    filter: invert(1);
  }
  
  .saveBtnContactDetail:hover {
    border: none;
    background-color: #fff;
    border-color: #fff;
  }
  
  .saveBtnContactDetail:focus {
    border: none;
    background-color: #fff;
    border-color: #fff;
  }
  
  .saveBtnContactDetailIcons {
    cursor: pointer;
    // font-size: 1.15rem;
  }
  
  .recording_play {
    width: 264px;
    height: 36px;
  }
  
  .disposition_drawer .ant-drawer-header {
    background: #f3f4f6 !important;
  }
  
  .disposition_drawer .ant-drawer-header-title {
    align-items: flex-start !important;
  }
  
  .disposition_drawer .ant-drawer-title {
    color: #344054 !important;
  }
  
  .badge_text {
    color: #ed1a69 !important;
    font-size: 16px;
  }
  
  .disposition_drawer button,
  .border_radius {
    border-radius: 8px !important;
  }
  
  .disposition_drawer .ant-btn-primary:hover:enabled,
  .disposition_drawer .ant-btn-primary:active:enabled,
  .border_radius:hover:enabled,
  .border_radius:active:enabled {
    background-color: transparent !important;
    border-color: #ed1a69 !important;
    color: #ed1a69 !important;
    box-shadow: none !important;
  }
  
  .ant-descriptions-item-content {
    display: table-cell !important;
  }
  
  .flag_img {
    height: 18px;
    margin-right: 8px;
  }
  
  .sm_font_size {
    font-size: 14px;
  }
  
  .add_phone_btn span {
    text-align: end;
  }
  
  .ant_design_icon {
    font-size: 16px;
    cursor: pointer;
    color: black;
  }
  
  .collapible-card .ant-card-body {
    padding: 0.6rem;
  }
  .custom-table {
    // border: 1px solid var(--grey-border);
    border-radius: 8px;
  }
  .custom-table-row {
    border-bottom: 1px solid var(--grey-border);
    border-left: 1px solid var(--grey-border);
    border-right: 1px solid var(--grey-border);
  }
  .custom-table-row:first-child {
    border-top: 1px solid var(--grey-border);
  }
  .custom-table-row:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .custom-table-row:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .custom-table-row:first-child > .ant-col:first-child {
    border-top-left-radius: 10px;
  }
  .custom-table-row:last-child > .ant-col:first-child {
    border-bottom-left-radius: 10px;
  }
  .custom-table-row > .custom-title {
    background: var(--grey-bg);
    font-weight: 700;
  }
  .custom-table-col:not(.has-child),
  .custom-title {
    min-height: 3.2rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  // .custom-table-col:has(.ant-form-item-has-error), .custom-table-row:has(.ant-form-item-has-error)> .custom-title {
  //   height: 3.8rem;
  // }
  .custom-table-child-row {
    text-align: center;
    font-size: 0.75rem;
  }
  .custom-table-child-row > .col {
    padding-left: 0;
    padding-right: 0;
  }
  .bb-1,
  .custom-table-child-row > .col > span {
    border-right: 1px solid var(--grey-border);
    display: grid;
  }
  .bb-1 {
    padding: 3px 0;
    border: 1px solid var(--grey-border);
    font-weight: 700;
    background: var(--grey-bg);
  }
  .bb-1:last-child {
    border-top-right-radius: 10px;
  }
  .custom-title > *:first-child {
    border-radius: 8px;
  }
  
  .custom-selector {
    color: #ed1a69;
  }
  
  .custom-selector .ant-select-selector {
    height: 1.6rem !important;
  }
  
  .child-elemnt-value {
    height: 25px !important;
    width: 100% !important;
  }
  .pull-right-flex {
    margin-right: 1rem;
    margin-left: auto;
    order: 2;
  }
  
  .common-modal > .ant-modal-content > .ant-modal-header {
    background-color: white;
  }
  .common-modal > .ant-modal-content > .ant-modal-header > .ant-modal-title {
    color: #000;
    text-align: center;
  }
  .common-modal>.ant-modal-content>.ant-modal-close>.ant-modal-close-x{
    color: #000;
  }
  .common-table .ant-table-container table > thead >tr>th {
    font-weight: 400;
  }
  
  .country-code-selector .ant-select-selector {
    height: auto !important;
  }
  
  .customised_popover button {
    border-radius: 6px !important;
  }
  .ant-input-sm {
    margin-left: 0 !important;
  }