// Todo List style start
.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
  
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    .todo-card {
      flex: 0 0 auto;
      border-radius: 7px;
      box-shadow: 0px 5px 8px -4px #07090e52;
      cursor: pointer;
      transition: all 0.4s;
      min-width: 22vw;
    }
  
    .todo-card:hover {
      transform: scale(1.012);
      box-shadow: 0px 9px 16px -5px #07090e52;
      z-index: 44;
    }
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrolling-wrapper-flexbox::-webkit-scrollbar {
    display: none;
  }
  
  .ant-table-tbody > tr > td {
    padding: 0.8rem;
  }
  
  .ant-table-thead > tr > th {
    padding: 0.8rem;
  }
  
  .progressBar {
    font-weight: bolder;
    height: 132px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
  
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .progressBar::-webkit-scrollbar {
    display: none;
  }
  
  .progressBar div {
    box-shadow: inset 0px 0px 3px 0px #07090e6b;
  }
  
  .dateDiv {
    border-radius: 7px;
    font-weight: bolder;
  }
  
  .muted_card {
    opacity: 0.3;
  }
  
  .ptp-disposition, .promise-to-pay-disposition {
    color: blue;
  }
  
  .bptp-disposition, .broken-promise-to-pay-disposition {
    color: #ab3cba;
  }
  
  .fptp-disposition, .pay-later-disposition {
    color: green;
  }
  
  .cb-disposition, .call-back-revisit-disposition {
    color: #e6c045;
  }
  
  .settlement-disposition {
    color: #9c0863;
  }
  
  .smarttext-disposition {
    color: #9a1307;
  }
  
  .smartvoice-disposition {
    color: #e656ae;
  }
  
  .ccu-disposition, .cibil-cleanup-disposition, .aecb-cleanup-disposition {
    color: #b93b08;
  }
  
  .ptp-progress, .promise-to-pay-progress {
    background-color: #d6e4ff;
  }
  
  .bptp-progress, .broken-promise-to-pay-progress {
    background-color: #ffd6e7;
  }
  
  .fptp-progress, .pay-later-progress {
    background-color: #f0f5ff;
  }
  
  .cb-progress, .call-back-revisit-progress {
    background-color: #ffeed4;
  }
  
  .settlement-progress {
    background-color: #e7d4ff;
  }
  
  .ccu-progress, .cibil-cleanup-progress, .aecb-cleanup-progress {
    background-color: #ffd4fb;
  }
  
  .smarttext-progress {
    background: #e9a19b;
  }
  
  .smartvoice-progress {
    background: #ffd6e7;
  }
  
  .active-todo-card {
    background-color: #018054;
  }
  
  .inactive-todo-card {
    background-color: #d9d9d9;
  }
  
  .disposition-progress {
    z-index: 2;
    min-height: 100%;
    left: 0;
  }
  
  .disposition-progress-div {
    transition: all 0.4s;
  }
  
  .disposition-progress-div:hover {
    transform: scale(1.012);
  }
  
  .disposition-type,
  .disposition-counts {
    z-index: 4;
  }
  
  .highlight {
    background-color: #fde8f0;
  }
  // Todo List style end